// State
export const state = () => ({
	loading: false,
	user: {},
	users: [],
	myProfile: {},
})


// Actions
export const actions = {

	// get all users
	async getAdmins({ commit }, data) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/admin`, {
					params: data
				})
				.then((response) => {
					commit('setUsers', response.data)
					commit('loading', false)
				})
		} catch (error) {
			this.$toast.error(error)
			commit('loading', false)
		}
	},

	// get user
	async getAdmin({ commit }, adminId) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/admin/${adminId}`)
				.then((response) => {
					commit('setUser', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			this.$toast.error(error)
			commit('loading', false)
		}
	},

	// Create user
	async createUser({ commit }, data) {
		commit('loading', true)
		await this.$axios.post(`/admin`, data).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			commit('loading', false)
			this.$router.push('/admin')
		}).catch(() => {
			commit('loading', false)
		})
	},

	// Reset password
	async resetUserPassword({ dispatch }, id) {
		const result = await this.$axios.post(`/admin/password/${id}/reset`, {}).then((res) => {
			const data = res.data
			return data
		}).catch(() => false)
		return result
	},

	// Change password
	async changeUserPassword({ dispatch }, data) {
		return await this.$axios.post(`/admin/password/change`, data).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			return true
		}).catch(() => {
			return false
		})
	},

	// Delete user
	async deleteUser({ dispatch }, id) {
		await this.$axios.delete(`/admin/${id}`).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			dispatch('getAdmins')
		})
	},

	// Active deactive
	async toggleUserActive({ dispatch }, id) {
		await this.$axios.patch(`/admin/${id}/toggle_active`, {}).then(async (response) => {
			dispatch('getAdmins')
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
		})
	},

	// Update user
	async updateUser({ dispatch }, data) {
		const id = data.admin_id
		delete data.admin_id
		await this.$axios.put(`/admin/${id}`, data).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			this.$router.push('/admin')
		}).catch(() => false)
	},

	// Update profile
	async updateProfile({ dispatch }, data) {
		await this.$axios.put(`/admin`, data).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
		}).catch(() => false)
	},

	// Get profile
	async myProfile({ commit }) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/admin/profile`)
				.then((response) => {
					commit('setProfile', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			this.$toast.error(error)
			commit('loading', false)
		}
	}
}


// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setUser(state, data) {
		state.user = data
	},
	setUsers(state, data) {
		state.users = data
	},
	setProfile(state, data) {
		state.myProfile = data
	}
}

// Getters
export const getters = {

}