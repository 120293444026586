// state
export const state = () => ({
	loading: false,
	languages: [],
	language: 'en-US',
	menubar: [
		{
			icon: 'mdi-home-edit-outline',
			title: 'home',
			children: [
				{
					title: 'slider',
					to: '/home/slider',
				},
				{
					title: 'articleCategory',
					to: '/home/article-category',
				},
				{
					title: 'article',
					to: '/home/article',
				},
				{
					title: 'menubar',
					to: '/home/menubar',
				},
			],
		},
		{
			icon: 'mdi-police-badge-outline',
			title: 'policyAndRegulation',
			children: [
				{
					title: 'policy',
					to: '/policy-and-regulation/policy',
				},
				{
					title: 'regulation',
					to: '/policy-and-regulation/regulation',
				},
				{
					title: 'approvedLicense',
					to: '/policy-and-regulation/approved-license',
				},
			],
		},
		{
			icon: 'mdi-information-variant',
			title: 'aboutUs',
			children: [
				{
					title: 'aboutUs',
					to: '/about-us/about',
				},
				{
					title: 'mission',
					to: '/about-us/mission',
				},
				{
					title: 'vision',
					to: '/about-us/vision',
				},
				{
					title: 'organizationChart',
					to: '/about-us/organization-chart',
				},
			],
		},
		{
			icon: 'mdi-hand-coin-outline',
			title: 'joinUs',
			children: [
				{
					title: 'career',
					to: '/join-us/career',
				},
				{
					title: 'projectBidding',
					to: '/join-us/project-bidding',
				},
			],
		},
		{
			icon: 'mdi-account-box-outline',
			title: 'contactUs',
			children: [
				{
					title: 'contactInformation',
					to: '/contact-us',
				},
				{
					title: `faqS`,
					to: '/contact-us/faq',
				},
				{
					title: 'generalInquiry',
					to: '/contact-us/general-inquiry',
				},
				{
					title: 'whistleBlower',
					to: '/contact-us/whistle-blower',
				},
			],
		},
		{
			icon: 'mdi-book-alphabet',
			title: 'languageStrings',
			to: '/language-strings'
		},
		// {
		// 	icon: 'mdi-account',
		// 	title: 'adminManagement',
		// 	to: '/admin'
		// },
	],
})

// Actions
export const actions = {
	// languages
	async languages({ commit }) {
		await this.$axios.get('/languages').then(response => {
			commit('setLanguages', response.data.data)
		}).catch(() => false)
	},
	// create job category
	async createLanguage({ commit }, form) {
		form.valid = undefined
		const formData = new FormData()
		formData.append('name', form.name)
		formData.append('short_code', form.short_code)
		formData.append('icon', form.icon)
		commit('loading', true)
		await this.$axios.post(`/languages`, formData).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			commit('loading', false)
			this.$router.push('/language')
		}).catch(() => {
			commit('loading', false)
		})
	},
	// Update language
	async updateLanguage({ dispatch }, form) {
		const id = form.id
		form.id = undefined
		const formData = new FormData()
		if (form.icon) {
			formData.append('icon', form.icon)
		}
		formData.append('name', form.name)
		formData.append('short_code', form.short_code)
		formData.append('status', form.status)
		await this.$axios.put(`/languages/${id}`, formData).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			dispatch('languages')
		}).catch(() => false)
	},
	// Delete language
	async deleteLanguage({ dispatch }, id) {
		await this.$axios.delete(`/languages/${id}`).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			dispatch('languages')
		}).catch((e) => this.$toast.error(e))
	},
	// Update
	// updateLanguage({ dispatch }, object) {
	// 	this.$axios.put(`/languages/${object.language_id}`, { is_default: object.status }).then(() => {
	// 		this.$toast.success('Language settings updated successfully')
	// 	}).catch(() => false)
	// },
}

// Mutations
export const mutations = {
	setLanguages: (state, v) => {
		state.languages = v
	},
	setLanguage: (state, v) => {
		state.language = v
	},
	loading(state, type) {
		state.loading = type
	},
}


// Getters
export const getters = {

	languages: state => {
		return state.languages.filter(o => o.is_active === true)
	},
	language: state => {
		return state.language
	},
}
