export const state = () => ({
	loading: false,
	languageStrings: {},
	newCodes: [], // ? this is used for stopping making new request of addLanguageStrings multiple times, check global.js you will see
	searchingText: ['text']
})

export const actions = {
	tl({ state }, code) {
		const translationJson = state.languageStrings
		if (!translationJson.data) {
			return code;
		}
		const langStr = translationJson?.data?.find(el => el.code === code)
		if (langStr?.value) {
			return langStr?.value[this.state?.settings?.language]
		}
		return code;
	},
	async languageStrings({ commit }, params) {
		commit('setLoading', true)
		try {
			await this.$axios.get('/public/language-string', { params }).then((response) => {
				commit('setLanguageString', { data: [...response.data.data] })
			})
			commit('setLoading', false)
			return true;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	async searchLanguageStrings({ commit }, params) {
		commit('setLoading', true)
		try {
			const response = await this.$axios.get('/public/language-string', { params }).then((response) => {
				return response.data;
			})
			commit('setLoading', false)
			return response;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	async addLanguageStrings({ commit }, form) {
		commit('setLoading', true)
		try {
			await this.$axios.post('/language-string', form)
				.then((response) => {
					if (response.status === 201) {
						commit('setPushLanguageString', response.data)
					}
				})
			commit('setLoading', false)
			return true;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	async updateLanguageStrings({ commit }, form) {
		const id = form.id;
		delete form.id;
		try {
			commit('setLoading', true)
			const response = await this.$axios.put(`/language-string/${id}`, form)
				.then((response) => {
					return true
				}).catch(() => { return false })
			commit('setLoading', false)
			return response;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	async deleteLanguageStrings({ commit }, id) {
		try {
			commit('setLoading', true)
			const response = await this.$axios.delete(`/language-string/${id}`)
				.then((response) => {
					commit('setLoading', false)
					if (response.status === 200) {
						return true;
					} else {
						return false;
					}
				})
			return response
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	newCode({ commit }, code) {
		commit('newCode', code)
	}

}

export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	setLanguageString: (state, v) => {
		state.languageStrings = v
	},
	setPushLanguageString: (state, v) => {
		state.languageStrings.data.unshift(v)
	},
	newCode: (state, v) => {
		if (!state.newCodes.includes(v)) {
			state.newCodes = [...state.newCodes, v]
		}
	}
}

export const getters = {
	localeLanguage: state => language => {
		if (!language) {
			return {}
		}
		if (!state.languageStrings?.data?.length) return {}
		const translation = state.languageStrings.data.map((data) => {
			return {
				code: data.code,
				text: data.value[language]
			}
		})
		return translation;
	},
	getSearchedLanguage: state => text => {
		if (!text) {
			return []
		}
		const filterCondition = (el) => {
			if (el?.code?.toLowerCase().includes(text?.toLowerCase())) {
				return true
			} else if (
				Object.values(el.value).some((string) =>
					string?.toLowerCase().includes(text?.toLowerCase())
				)
			) {
				return true
			}
		}

		const searchData =
			state.languageStrings.data.filter(filterCondition)
		return searchData
	}
}
