// State
export const state = () => ({
	loading: false,
	subMenubars: [],
	subMenubar: {}
})

// Actions
export const actions = {
	async subMenubars({ commit }, id) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/sub-menubar/${id}`)
				.then((response) => {
					commit('setSubMenubars', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async subMenubar({ commit }, id) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/sub-menubar/single/${id}`)
				.then((response) => {
					commit('setSubMenubar', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async deleteSubMenubar({ commit, dispatch }, id) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.delete(`/sub-menubar/${id}`)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async createSubMenubar({ commit }, data) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.post(`/sub-menubar`, data)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateSubMenubar({ commit }, form) {
		const data = { ...form }
		const { id } = data
		delete data.id

		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/sub-menubar/${id}`, data)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateSequence({ commit }, form) {
		const data = [...form]
		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/sub-menubar/bulk-sequence`, data)
				.then(async (response) => {
					commit('loading', false)
					const resData = response.data
					const message = await this.dispatch(
						'language-strings/tl',
						resData?.message
					)
					this.$toast.success(message)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
}


// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setSubMenubars(state, data) {
		state.subMenubars = data
	},
	setSubMenubar(state, data) {
		state.subMenubar = data
	}
}

// Getters
export const getters = {

}