// State
export const state = () => ({
	loading: false,
	projects: {
		data: [
			{
				title: '',
			}
		]
	},
})

// Actions
export const actions = {
	// get all projects
	async projects({ commit }, data) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/projects`, {
					params: data
				})
				.then((response) => {
					commit('setProjects', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			this.$toast.error(error)
			commit('loading', false)
		}
	},
	// Create Project
	async createProjects({ commit }, data) {
		data.valid = undefined
		commit('loading', true)
		await this.$axios.post(`/projects`, data).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			commit('loading', false)
			this.$router.push('/projects')
		}).catch(() => {
			commit('loading', false)
		})
	},
	// Delete
	async deleteProject({ dispatch }, id) {
		await this.$axios.delete(`/projects/${id}`).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			dispatch('projects')
		}).catch((e) => this.$toast.error(e))
	},
	// Update projects
	async updateProjects({ dispatch }, data) {
		const id = data.id
		data.id = undefined
		await this.$axios.put(`/projects/${id}`, data).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			dispatch('projects')
		}).catch(() => false)
	},
}


// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setProjects(state, data) {
		state.projects = data
	},
}

// Getters
export const getters = {}