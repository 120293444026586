// State
export const state = () => ({
	loading: false,
	roles: [],
	permissions: [],
	permissionModules: []
})

// Actions
export const actions = {
	// ? Roles
	async roles({ commit }, id) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/roles`, {
					params: { role_id: id }
				})
				.then((response) => {
					commit('roles', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
			this.$toast.error(error)
		}
	},

	// ? Permission modules (CRUD)
	async permissionModules({ commit }, roleId) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/permission-modules`, {
					params: { role_id: roleId }
				})
				.then((response) => {
					commit('permissionModules', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
			this.$toast.error(error)
		}
	},

	// ? Permissions
	async permissions({ commit }) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/permissions`)
				.then((response) => {
					commit('permissions', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
			this.$toast.error(error)
		}
	},

	// ? Update permission by role / permission
	async update({ commit }, payload) {
		try {
			commit('loading', true)
			const updatePermissions = await {
				role_update: {
					code: payload.form.code,
					description: payload.form.description,
					name: payload.form.name,
					is_active: payload.form.is_active
				},
				permission_modules: payload.permissions.map((o) => {
					return {
						permission_id: o.permissions.permission_id,
						can_view: o.permissions.can_view,
						can_create: o.permissions.can_create,
						can_update: o.permissions.can_update,
						can_delete: o.permissions.can_delete,
					}
				}),
			};
			await this.$axios
				.put(`/roles/${payload.form.role_id}`, updatePermissions)
				.then(() => {
					this.$toast.success('Permissions are updated.')
					commit('loading', false)
					this.$router.push(('/permissions'))
				})
		} catch (error) {
			commit('loading', false)
			this.$toast.error(error)
		}
	},

	// ? Create permission by role / permission
	async create({ commit }, payload) {
		try {
			commit('loading', true)
			const createPermissions = await {
				...payload.form,
				permission_modules: payload.permissions.map((o) => {
					return {
						permission_id: o.permissions.permission_id,
						can_view: o.permissions.can_view,
						can_create: o.permissions.can_create,
						can_update: o.permissions.can_update,
						can_delete: o.permissions.can_delete,
					}
				}),
			};
			await this.$axios
				.post(`/roles`, createPermissions)
				.then(() => {
					this.$toast.success('Role is created.')
					commit('loading', false)
					this.$router.push(('/permissions'))
				})
		} catch (error) {
			commit('loading', false)
			this.$toast.error(error)
		}
	},
}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	roles(state, data) {
		state.roles = data
	},
	permissionModules(state, data) {
		state.permissionModules = data
	},
	permissions(state, data) {
		state.permissions = data.map(function (e) {
			e.permissions = state.permissionModules.find(o => o.permission_id === e.permission_id)
			return e
		})
	},
}

// Getters
export const getters = {
	findPermissionById: state => id => {
		return state.permissionModules.find(o => o.permission_id === id)
	}
}