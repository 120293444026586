// State
export const state = () => ({
	loading: false,
	category: {
		data: [
			{
				category_name: {},
			}
		]
	},
	news: {
		data: [
			{
				title: {},
				short_description: {},
				category_name: {},
			}
		]
	},
	NewsId: {}
})


// Actions
export const actions = {
	// get all news categories
	async category({ commit }, data) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/news/category`, {
					params: data
				})
				.then((response) => {
					commit('setCategory', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			this.$toast.error(error)
			commit('loading', false)
		}
	},
	// Delete news category
	async deleteCategory({ dispatch }, id) {
		await this.$axios.delete(`/news/category/${id}`).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			dispatch('category')
		}).catch((e) => this.$toast.error(e))
	},
	// create news category
	async createCategory({ commit }, data) {
		data.valid = undefined
		commit('loading', true)
		await this.$axios.post(`/news/category`, data).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			commit('loading', false)
			this.$router.push('/news/category')
		}).catch(() => {
			commit('loading', false)
		})
	},
	// Update news category
	async updateCategory({ dispatch }, data) {
		const id = data.id
		data.id = undefined

		await this.$axios.put(`/news/category/${id}`, data).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			dispatch('category')
		}).catch(() => false)
	},
	// get all news
	async news({ commit }, data) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/news`, {
					params: data
				})
				.then((response) => {
					commit('setNews', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			this.$toast.error(error)
			commit('loading', false)
		}
	},
	// create news
	async createNews({ commit }, form) {
		form.valid = undefined
		const formData = new FormData()
		formData.append('title', JSON.stringify(form.title))
		formData.append('short_description', JSON.stringify(form.short_description))
		formData.append('description', JSON.stringify(form.description))
		// formData.append('post_date', form.post_date)
		formData.append('news_category_id', form.news_category_id)
		formData.append('post_by', form.post_by)
		formData.append('image', form.image)
		commit('loading', true)
		await this.$axios.post(`/news`, formData).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			commit('loading', false)
			this.$router.push('/news/news')
		}).catch(() => {
			commit('loading', false)
		})
	},
	// Delete language
	async deleteNews({ dispatch }, id) {
		await this.$axios.delete(`/news/${id}`).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			dispatch('news')
		}).catch((e) => this.$toast.error(e))
	},
	// get single news by Id
	async NewsId({ commit }, id) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/news/${id}`)
				.then((response) => {
					commit('setNewsId', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			this.$toast.error(error)
			commit('loading', false)
		}
	},
	// Update News
	async updateNews({ dispatch }, form) {
		const id = form.news_id
		form.news_id = undefined
		const formData = new FormData()
		if (form.image) {
			formData.append('image', form.image)
		}
		formData.append('title', JSON.stringify(form.title))
		formData.append('short_description', JSON.stringify(form.short_description))
		formData.append('description', JSON.stringify(form.description))
		// formData.append('post_date', form.post_date)
		formData.append('news_category_id', form.news_category_id)
		formData.append('post_by', form.post_by)
		await this.$axios.put(`/news/${id}`, formData).then(async (response) => {
			const resData = response.data
			const message = await this.dispatch(
				'language-strings/tl',
				resData?.message
			)
			this.$toast.success(message)
			dispatch('news')
			this.$router.push('/news/news')
		}).catch(() => false)
	},
}


// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setCategory(state, data) {
		state.category = data
	},
	setNews(state, data) {
		state.news = data
	},
	setNewsId(state, data) {
		state.NewsId = data
	}
}


// Getters
export const getters = {}