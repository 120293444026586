// State
export const state = () => ({
	loading: false,
	menubars: [],
	menubar: {}
})

// Actions
export const actions = {
	async menubars({ commit }, data) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/menubar`, {
					params: data
				})
				.then((response) => {
					commit('setMenubars', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async menubar({ commit }, id) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/menubar/${id}`)
				.then((response) => {
					commit('setMenubar', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async deleteMenubar({ commit, dispatch }, id) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.delete(`/menubar/${id}`)
				.then((response) => {
					commit('loading', false)
					dispatch('menubars')
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async createMenubar({ commit }, data) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.post(`/menubar`, data)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateMenubar({ commit }, data) {
		const { id } = data
		delete data.id

		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/menubar/${id}`, data)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateSequence({ commit }, form) {
		const data = [...form]
		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/menubar/bulk-sequence`, data)
				.then(async (response) => {
					commit('loading', false)
					const resData = response.data
					const message = await this.dispatch(
						'language-strings/tl',
						resData?.message
					)
					this.$toast.success(message)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
}


// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setMenubars(state, data) {
		state.menubars = data
	},
	setMenubar(state, data) {
		state.menubar = data
	}
}

// Getters
export const getters = {

}