// State
export const state = () => ({
	loading: false,
	items: [],
	item: {}
})

// Actions
export const actions = {
	async gets({ commit }) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/regulation`)
				.then((response) => {
					commit('setItems', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async get({ commit }, id) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/regulation/${id}`)
				.then((response) => {
					commit('setItem', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async delete({ commit, dispatch }, id) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.delete(`/regulation/${id}`)
				.then((response) => {
					commit('loading', false)
					dispatch('gets')
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async create({ commit }, data) {
		try {
			const formData = new FormData()
			for (const key in data) {
				if (key === 'title') {
					formData.append(key, JSON.stringify(data[key]));
				} else {
					formData.append(key, data[key]);
				}
			}
			commit('loading', true)
			const response = await this.$axios
				.post(`/regulation`, formData)
				.then(async (response) => {
					commit('loading', false)
					const resData = response.data
					const message = await this.dispatch(
						'language-strings/tl',
						resData?.message
					)
					this.$toast.success(message)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async update({ commit }, form) {
		const data = { ...form }
		const { id } = data
		delete data.id
		const formData = new FormData()
		for (const key in data) {
			if (key === 'title') {
				formData.append(key, JSON.stringify(data[key]));
			} else {
				formData.append(key, data[key]);
			}
		}

		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/regulation/${id}`, formData)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateSequence({ commit }, form) {
		const data = [...form]
		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/regulation/bulk-sequence`, data)
				.then(async (response) => {
					commit('loading', false)
					const resData = response.data
					const message = await this.dispatch(
						'language-strings/tl',
						resData?.message
					)
					this.$toast.success(message)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
}


// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setItems(state, data) {
		state.items = data
	},
	setItem(state, data) {
		state.item = data
	}
}

// Getters
export const getters = {}